import axios from 'axios';
import { Message, Notification } from 'ant-design-vue';
import store from "../store/index"

// import errorCode from '@/utils/errorCode'
const request = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? "https://yshzk.com" : "", // 接口的基础URL，根据实际情况修改
  timeout: 50000 // 50s请求超时时间，根据实际情况修改
});

// 是否显示重新登录
export let isRelogin = { show: false };

// 添加请求拦截器
request.interceptors.request.use(
  config => {
    if (config.params && !config.params.noSpinning) {
      store.commit("CHANGE_GLOBALSPINNING", true)
    }
    const token = localStorage.getItem('token'); // 从本地存储中获取 token
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // 设置请求头的 Authorization 字段
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  response => {
    store.commit("CHANGE_GLOBALSPINNING", false)
    setTimeout(() => {
      store.commit("CHANGE_GLOBALSPINNING", false)
    }, 4000)
    const { data } = response;
    // 未设置状态码则默认成功状态
    // const code = data.state || 1;
    // data.status == 0成功
    // 获取错误信息
    const msg = response.data.message
    // 判断特定接口，这里以 'we/wer/get' 为例
    if (response.config.url.includes('media/wechat/member/queryIsMember')) {
      return response.data;
    }
    if (response.config.url.includes('media/wechat/userGoodsOrder/queryOrderStatus')) {
      return response.data;
    }
    if (data.state !== undefined) {
      if (data.state !== 0) {
        Message.error(msg)
        return Promise.reject('拦截器响应：error' + msg)
      } else {
        return response.data;
      }
    }
  },
  error => {
    store.commit("CHANGE_GLOBALSPINNING", false)
    console.log('err' + error)
    let { message } = error;
    let code = message.substr(message.length - 3)
    if (code == 503) {
      message = '网络开小差了，请稍后再试'
    } else if (message === "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message.error(message)
    return Promise.reject(error);
  }
);

export const fetchData = () => {
  return request.get('/login');
};

export default request