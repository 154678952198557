<template>
  <a-config-provider :locale="zh_CN">
     <router-view/>
  </a-config-provider>
</template>

<script setup>
import {ref} from "vue" ;
import zhCN from 'ant-design-vue/es/locale/zh_CN';
const zh_CN=ref(zhCN)
</script>
<style lang="less">
@import "~@/assets/styles/global.less";
// 引入自定义样式
@import "~@/assets/styles/free.less";
// 引入基础样式库
@import "~@/assets/styles/base.less";


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

</style>
