import { createStore } from 'vuex'
import { encrypt } from '@/assets/utils/tools'
import {initStateData}  from "@/assets/utils/common"

const _token=(typeof localStorage.token !="undefined") ? localStorage.token: ""
const _userId=(typeof localStorage.userId !="undefined") ? localStorage.userId: ""
const _userInfo=(typeof localStorage.userInfo !="undefined") ? JSON.parse(localStorage.userInfo): {}

export default createStore({
  state: {
    loginRes:{}, // 登陆返回的数据
    token:_token,
    userId:_userId, // 用户id
    userInfo:_userInfo, //用户信息
    globalSpinning:false, // 是否正在加载
  },
  getters: {
    // 登陆状态 1登录  0  未登录
    isLogin (state){
      return  state.userId ? 1 : 0
    },
  },
  mutations: {
    // 更改加载状态
    CHANGE_GLOBALSPINNING(state,data){
      state.globalSpinning=data
      console.log("spining",data)
    },
    // 存储登录返回的数据
    SAVE_LOGINRES(state,data){
      state.loginRes=data
      state.token=encrypt(data.username)
      state.userId=data.data
      localStorage.setItem('token', state.token)
      localStorage.setItem('userId', state.userId)
    },
    //存储用户信息
    SAVE_USERINFO(state,data){
      state.userInfo=data
      localStorage.setItem('userInfo', JSON.stringify(data))
    },
    // 退出
    LOGOUT (state) {
      initStateData(state)
      console.log("state",state)
      // state.loginRes={}
      // state.token=''
      // state.userId=''
      // state.userInfo=''
      localStorage.clear()
      sessionStorage.clear()
    }
  },
  actions: {
  },
  modules: {
  }
})
